import { useEffect } from 'react';
import LazyLoad from 'react-lazyload';

import { ContinueWatchingShelfRow } from '@@src/components/PageRows/PageRow';
import CarouselShelfV2 from '@@src/components/Shelf/CarouselShelfV2';
import PlaceholderShelf from '@@src/components/Shelf/PlaceholderShelf';
import { useAppDispatch, useAppSelector } from '@@src/hooks/store';
import { getContinueWatchingItemsAsyncThunk, selectContinueWatchingItems } from '@@stores/continueWatchingSlice';

const ContinueWatchingCarouselShelf = ({ row, rowIndex }: { row: ContinueWatchingShelfRow, rowIndex: number }) => {
  const items = useAppSelector(selectContinueWatchingItems);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!row.ref?.url) {
      return;
    }

    dispatch(
      getContinueWatchingItemsAsyncThunk({
        url:
          row.ref.url,
      }),
    );
  }, [dispatch, row]);

  return (
    <LazyLoad
      offset={100}
      unmountIfInvisible
      placeholder={<PlaceholderShelf collectionTitle={row.collection.title} collectionDisplayType={row.collection.displayType}/>}
      once={'route' in row.collection}
    >
      <CarouselShelfV2
        collection={{
          ...row.collection,
          items,
        }}
        rowIndex={rowIndex}
        continueWatching
      />
    </LazyLoad>
  );
};

export default ContinueWatchingCarouselShelf;
