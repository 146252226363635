import { get } from 'lodash';

import CatalogApiEpisodeTransformer from '@@src/transformers/CatalogApiEpisodeTransformer';
import CatalogApiMovieOrTvProgramTransformer from '@@src/transformers/CatalogApiMovieOrTvProgramTransformer';
import CatalogApiSeriesTransformer from '@@src/transformers/CatalogApiSeriesTransformer';
import { paths } from '@@types/CatalogueApi';
import OnDemand from '@@types/OnDemand';
import { catalogueHttpClientWithAuth, logHttpError } from '@@utils/HttpClient';

export const MORE_LIKE_THIS_PATH = '/recs/you-may-like/{id}' as const;
export const RECOMMENDED_END_CARD_PATH = '/recs/recommended-end-card/{id}' as const;

export type MoreLikeThisResponse = paths[typeof MORE_LIKE_THIS_PATH]['get']['responses']['200']['content']['application/json'];
export type RecommendedEndCardResponse = paths[typeof RECOMMENDED_END_CARD_PATH]['get']['responses']['200']['content']['application/json'];

const getMoreLikeThisPromises: Record<string, Promise<MoreLikeThisResponse>> = {};
export async function getMoreLikeThis(entityId: string, language: string = 'en'): Promise<MoreLikeThisResponse> {
  const httpClient = catalogueHttpClientWithAuth(language);
  const cacheKey = `${entityId}${language}`;

  if (!(cacheKey in getMoreLikeThisPromises) || !(getMoreLikeThisPromises[cacheKey] instanceof Promise)) {
    getMoreLikeThisPromises[cacheKey] = httpClient.get<MoreLikeThisResponse>(MORE_LIKE_THIS_PATH.replace('{id}', entityId))
      .then(({ data }) => {
        getMoreLikeThisPromises[cacheKey] = undefined;
        return data;
      })
      .catch((e) => {
        getMoreLikeThisPromises[cacheKey] = undefined;
        const error = new Error('Unhandled Catalogue API error');
        logHttpError(e, error, 'error');
        throw error;
      });
  }

  return getMoreLikeThisPromises[cacheKey];
}

export async function recommendedEndCard(uuid: string, language: string = 'en'): Promise<OnDemand.Video> {
  const httpClient = catalogueHttpClientWithAuth(language);

  try {
    const { data } = await httpClient.get<RecommendedEndCardResponse>(RECOMMENDED_END_CARD_PATH.replace('{id}', uuid));
    const recommendationId = data.recommendationID;
    const firstRecommendation = data.recommendedItems[0] ?? null;
    let recommendedContent = null;

    if (firstRecommendation) {
      if (firstRecommendation.entityType === 'MOVIE' || firstRecommendation.entityType === 'TV_PROGRAM') {
        recommendedContent = CatalogApiMovieOrTvProgramTransformer.transform(firstRecommendation);
      }

      if (firstRecommendation.entityType === 'TV_SERIES') {
        const recommendedSeries = CatalogApiSeriesTransformer.transform(firstRecommendation);
        recommendedContent = ({
          ...CatalogApiEpisodeTransformer.transform({
            ...get(firstRecommendation, 'seasons.0.episodes.0'),

            // get these attributes from the series object as they are not available in the episode object
            countries: firstRecommendation.countries,
            casts: firstRecommendation.casts,
            crews: firstRecommendation.crews,
            qualities: firstRecommendation.qualities,

            // ibmsTitleID/pilatId is not returned in episode object of TvSeries response
            ibmsTitleID: '',
          }),
          // extra attributes needed for the Rec End Card
          numberOfSeasons: recommendedSeries.numberOfSeasons,
          languages: recommendedSeries.languages,
          // use the series key art instead of episode key art
          odImageId: recommendedSeries.odImageId,
        });
      }
    }

    if (!recommendedContent) {
      return null;
    }

    return {
      ...recommendedContent,
      recommendationId,
      recommendationVariant: data.recommendationVariant,
    };
  } catch (e) {
    const error = new Error('Error on recommendedEndCard');
    logHttpError(e, error, 'error');
    return null;
  }
}

export default {
  recommendedEndCard,
};
