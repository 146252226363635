import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { fill } from 'lodash';
import { FunctionComponent, ReactElement, useCallback, useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

import { GridRow, ShelfRow } from '@@src/components/PageRows/PageRow';
import ShelfTitle from '@@src/components/Shelf/ShelfTitle';
import CircleTile from '@@src/components/Tiles/CircleTile';
import { createPlaceholderTiles, PlaceholderTile } from '@@src/components/Tiles/PlaceholderTile';
import TallTile from '@@src/components/Tiles/TallTile';
import { getTileComponentByTileItem } from '@@src/components/Tiles/Tile';
import OdContainer from '@@src/components/Utils/OdContainer';
import { getPageRowItems, PAGESIZE_CAROUSEL_SHELF } from '@@src/services/PageService';
import { getCurrentBreakpoint } from '@@src/styles/breakpoints';
import fontFamily from '@@src/styles/typography/fontFamily';
import OnDemand from '@@types/OnDemand';
import DataLayer from '@@utils/DataLayer';
import { getTileSpec, insertArrayAtIndex } from '@@utils/helpers';

export function buildTileElement(
  tileItem: OnDemand.Tile,
  itemType: string,
  rowIndex: number,
  tileIndex: number,
  shelfName: string = '',
  shelfLocation: string = '',
  onDelete: (id: string) => void = () => {
    // do nothing
  },
  lazyloadImages: boolean = true,
): ReactElement {
  let TileComponent;

  function setClickSource() {
    DataLayer.setClickSource(shelfName, shelfLocation, rowIndex + 1, tileIndex + 1);
  }

  const tileComponentProps: any = {
    onClick() {
      setClickSource();
    },
  };

  // itemType here is the row type / display type
  switch (itemType) {
    case 'collection':
      TileComponent = TallTile;
      break;

    case 'circle':
      TileComponent = CircleTile;
      break;

    default:
      TileComponent = getTileComponentByTileItem(tileItem.item);
      tileComponentProps.shelfLocation = shelfLocation;
      break;
  }

  return (
    <TileComponent
      key={tileItem.item.id}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...tileItem}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...tileComponentProps}
      imageProps={{
        ...tileItem.imageProps,
        lazyload: lazyloadImages,
      }}
    />
  );
}

export const sharedStyle = (theme: Theme) => {
  return createStyles({
    shelf: {
      position: 'relative',
    },
    shelfName: {
      marginBottom: '0.50em',
      fontFamily: fontFamily.ubuntu,
      fontWeight: 'bold',
      position: 'relative',
    },
    shelfTitle: {
      marginBottom: '0.875rem',
      [theme.breakpoints.down('md')]: {
        marginBottom: '0.75rem',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: '0.625rem',
      },
    },
  });
};

const useStyles = makeStyles(sharedStyle);

interface ShelfProps {
  row: GridRow | ShelfRow;
  rowIndex: number;
  renderShelfItems(props: {
    tiles: ReactElement[];
    hasMoreItems: boolean;
    isLoadingMoreItems: boolean;
    loadMoreItems(callback?: () => void): void;
    itemType: string;
    tileSpec: {
      width: number;
      height: number;
      imageHeight: number;
      itemsPerView: number;
      orientation: string;
    };
  });
  pageSize?: number;
  lazyloadImages?: boolean;
}

const ShelfShell: FunctionComponent<ShelfProps> = (props) => {
  const {
    row,
    rowIndex,
    renderShelfItems,
    pageSize = PAGESIZE_CAROUSEL_SHELF,
    lazyloadImages = true,
  } = props;

  const classes = useStyles(props);

  const { width: windowWidth } = useWindowSize();

  const breakpoint = getCurrentBreakpoint();

  const { name, itemType, items: rowItems = [] } = row;
  const dataLayerShelfName = name.replace(/:/, '');

  const tileSpec = getTileSpec(itemType, windowWidth, breakpoint);
  const { imageHeight } = tileSpec;

  const [numberOfTilesPerView, setNumberOfTilesPerView] = useState(8);

  // keep track of delete tile ids so that we can filter them
  const [deletedTileIds, setDeletedTileIds] = useState<number[]>([]);

  const onDelete = (id) => {
    setDeletedTileIds((_deletedTileIds) => {
      return _deletedTileIds.concat([id]);
    });
  };

  const getShelfLocation = useCallback((tileIndex) => {
    return `carousel:${dataLayerShelfName}:${rowIndex + 1}:${tileIndex + 1}`;
  }, [dataLayerShelfName, rowIndex]);

  const initialTileElements = rowItems.map((tileItem, i) => {
    return buildTileElement(tileItem, itemType, rowIndex, i, name, getShelfLocation(i), onDelete, lazyloadImages);
  });

  // total items in the row
  const [totalItems, setTotalItems] = useState<number>(row.totalItems || 0);

  // where are we up to
  const [rangeTo, setRangeTo] = useState<number>(row.rangeTo || 0);

  // loading = true means the ajax call to load the items are still in progress
  // initial state is true until loaded
  const [isLoadingMoreItems, setIsLoadingMoreItems] = useState<boolean>(true);

  // total items that have been loaded
  const [loadedItemsCount, setLoadedItemsCount] = useState<number>(rowItems.length);

  const [rowLoaded, setRowLoaded] = useState<boolean>(row.items !== undefined);

  // do we have more items?
  const [hasMoreItems, setHasMoreItems] = useState<boolean>(loadedItemsCount < totalItems || row.items === undefined);

  // The tiles that will be rendered in the shelf, it has been filtered, eg: expiry
  const [tileElements, setTileElements] = useState<ReactElement[]>(initialTileElements || []);

  useEffect(() => {
    // we'll set loading to false after mounted
    setIsLoadingMoreItems(false);
    setNumberOfTilesPerView(tileSpec.itemsPerView);
  }, [tileSpec.itemsPerView]);

  const loadMoreItems = useCallback((callback?: () => void) => {
    // don't load more items if there are no more items or loading is in progress
    if (!hasMoreItems) return;

    setIsLoadingMoreItems(true);

    const rangeFrom = rangeTo + 1;
    const _rangeTo = rangeFrom + pageSize - 1;

    const insertIndex = tileElements.length;

    // insert placeholder tiles while we're loading the page row items
    setTileElements((currentTileElements) => {
      const length = Math.min(totalItems - loadedItemsCount, pageSize);
      const placeholderTiles = fill(Array(length), null).map((_x, i) => {
        const key = `${rangeFrom}-${loadedItemsCount + i}`;
        return <PlaceholderTile key={key} imageHeight={imageHeight}/>;
      });
      return insertArrayAtIndex(currentTileElements, placeholderTiles, insertIndex);
    });

    getPageRowItems(row, {}, rangeFrom, _rangeTo).then((pageRowItems) => {
      const { items } = pageRowItems;

      // if the number of items is less than the pageSize, we assume there is no more items
      const hasNoMoreItems = items.length < pageSize;
      if (hasNoMoreItems) {
        setHasMoreItems(false);
      }

      // build the tiles
      setTileElements((currentTileElements) => {
        const newTileElements = items.filter((tileItem: OnDemand.Tile) => {
          if (tileItem) {
            return (tileItem.item && (!('expired' in tileItem.item) || !tileItem.item.expired));
          }

          return false;
        }).map((tileItem: OnDemand.Tile, i) => {
          return buildTileElement(tileItem, itemType, rowIndex, i, name, getShelfLocation(currentTileElements.length + i), onDelete, lazyloadImages);
        });

        const _tileElements = insertArrayAtIndex(currentTileElements, newTileElements, insertIndex);

        // trim the extra placeholders
        if (hasNoMoreItems) {
          return _tileElements.slice(0, insertIndex + items.length);
        }

        return _tileElements;
      });

      setLoadedItemsCount((_loadedItemsCount) => {
        return _loadedItemsCount + pageRowItems.items.length;
      });

      setRowLoaded(true);
      setRangeTo(pageRowItems.rangeTo);
      setTotalItems(pageRowItems.totalItems);
    }).finally(() => {
      setIsLoadingMoreItems(false);
      if (callback) callback();
    });
  }, [getShelfLocation, hasMoreItems, imageHeight, itemType, lazyloadImages, loadedItemsCount, name, pageSize, rangeTo, row, rowIndex, tileElements.length, totalItems]);

  const handleShelfTitleClick = useCallback(() => {
    DataLayer.setClickSource(
      `${name}:view all`,
      'carousel',
      rowIndex + 1,
      null,
    );
  }, [name, rowIndex]);

  // filter deleted items
  let filteredTiles = tileElements.filter((tileElement) => {
    const { props: { is, item } } = tileElement;
    return is === 'placeholder' || deletedTileIds.indexOf(item.id) === -1;
  });

  // row items have not been loaded, lets render placeholder tiles
  if (!rowLoaded) {
    filteredTiles = createPlaceholderTiles(tileSpec.imageHeight, numberOfTilesPerView, `shelf-placeholder-${row.name}`);
  }

  // load more items if row hasn't been loaded
  if (!isLoadingMoreItems && loadedItemsCount === 0 && hasMoreItems) {
    loadMoreItems();
  }

  const renderShelfItemsProps = {
    itemType,
    tiles: filteredTiles,
    tileSpec,
    hasMoreItems,
    loadMoreItems,
    isLoadingMoreItems,
  };

  if (filteredTiles.length > 0) {
    return (
      <div className={classes.shelf}>
        <OdContainer className={classes.shelfTitle}>
          <ShelfTitle
            name={name}
            route={row.route}
            onClick={handleShelfTitleClick}
          />
        </OdContainer>
        {renderShelfItems(renderShelfItemsProps)}
      </div>
    );
  }

  return null;
};

export default ShelfShell;
