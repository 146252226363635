import { Box, Button } from '@material-ui/core';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import LiveBadge from '@@src/components/DataDisplay/Badge/LiveBadge';
import VideoProgressBar from '@@src/components/ProgressBar/VideoProgressBar';
import AvailableDateTimeLabel from '@@src/components/Utils/AvailableDateTimeLabel';
import { VideoPlayWidgetProps, useStyles } from '@@src/components/Video/VideoPlayWidget';
import { useAppSelector } from '@@src/hooks/store';
import { durationShortFormatParams } from '@@src/i18n';
import { ReactComponent as PlayIcon } from '@@src/images/icons/play-circle.svg';
import { generatePathFromLinkProps } from '@@src/routes';
import { selectProgress } from '@@stores/UserActivityStore';

const AvailableVideoPlayWidget: FunctionComponent<VideoPlayWidgetProps> = (props) => {
  const {
    video,
    label = null,
  } = props;

  const classes = useStyles(props);
  const { t } = useTranslation('common');
  const progressMap = useAppSelector(selectProgress);

  const progressData = progressMap[video.catalogueId] ?? {
    percent: 0,
    seconds: 0,
  };

  const progressPosition = progressData.seconds ?? 0;

  let progressMessage = t('video.duration', {
    duration: video.duration,
    formatParams: {
      duration: durationShortFormatParams,
    },
  });

  if (video.isLiveStream) {
    progressMessage = null;
  } else if (progressPosition) {
    if (progressData.percent !== 100) {
      progressMessage = `${t('video.duration', {
        duration: (video.duration - progressPosition) > 60 ? video.duration - progressPosition : 60,
        formatParams: {
          duration: durationShortFormatParams,
        },
      })}  ${t('playback.remaining')}`;
    }
  }

  let playLabel = label;

  if (label === null) {
    playLabel = progressPosition ? t('playback.resume') : t('playback.play');
  }

  const playLabelId = `play-label-${video.id}`;

  const watchLink = generatePathFromLinkProps({
    name: 'watch',
    params: {
      id: video.id,
    },
  });

  const hasProgress = progressPosition !== null && progressPosition > 0;

  return (
    <div className={clsx(classes.root, { hasProgress })}>
      <div className={classes.playButtonContainer}>
        <Button
          aria-describedby={playLabelId}
          aria-label={t('playback.play')}
          data-testid="video-play-widget-play-button"
          disableElevation
          disableRipple
          disableFocusRipple
          className={classes.playButton}
          component={Link}
          to={watchLink}
        >
          <PlayIcon/>
        </Button>
      </div>
      {!video.isLiveStream && (
        <div className={classes.metadata}>
          {
            playLabel !== '' && (
              <div
                className={classes.label}
                data-testid="video-play-widget-play-label"
                id={playLabelId}
              >
                {playLabel}
              </div>
            )
          }
          <div className={classes.title}>{video.title}</div>
          <div className={classes.duration}>{progressMessage}</div>
          {progressData.percent > 0 ? (
            <VideoProgressBar
              classes={{ progressBarFill: classes.progressBarFill }}
              progressPercent={progressData.percent}
            />
          ) : null}
        </div>
      )}
      {video.isLiveStream && (
        <div className={classes.centeredContent}>
          <div className={classes.availabilityLabel}>
            {t('playback.play')}
          </div>
          <LiveBadge classes={{ root: classes.badge }} color="success" overlap="rectangular">
            <Box>
              <div className={classes.watchLabel}>
                <AvailableDateTimeLabel start={video.availableDate}/>
              </div>
            </Box>
          </LiveBadge>
        </div>
      )}
    </div>
  );
};

export default AvailableVideoPlayWidget;
