import { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory, useParams } from 'react-router-dom';

import useRecommendedItems from '@@src/hooks/useRecommendedItems';
import OnDemand from '@@types/OnDemand';
import { SSRFunctionComponent } from '@@types/ssr';
import DataLayer from '@@utils/DataLayer';
import * as StructuredData from '@@utils/StructuredData';
import { logVideoNotMatching } from '@@utils/helpers';
import Logger from '@@utils/logger/Logger';

import { getMpxMediaById } from '../apis/CatalogueApi';
import VideoSummary from '../components/Content/VideoSummary';
import { NotFoundError } from '../components/Error/Error';
import { getResizedUrl } from '../components/Html/Image';
import DetailsPageLayout from '../components/PageLayouts/DetailsPageLayout';
import PageRows from '../components/PageRows/PageRows';
import AlternateLinks from '../components/Utils/AlternateLinks';
import HttpRedirect from '../components/Utils/HttpRedirect';
import UnfurlingMetaTags from '../components/Utils/UnfurlingMetaTags';
import useQuery from '../hooks/useQuery';
import { generateFullUrlFromLinkProps, generatePathFromLinkProps } from '../routes';
import { getOgType } from '../services/VideoService';

export interface VideoPageProps {
  video: OnDemand.Video;
}

export const videoIdsToBeServedInOldUrl = [
  '2089214019938',
  '2089214531915',
  '2089214531916',
  '2089214531917',
  '2089215555968',
  '2095938115998',
  '2095938115999',
  '2095938116001',
  '2095939139879',
  '2095940163634',
  '2097055299535',
  '2097055299536',
  '2097055299537',
  '2097055811629',
  '2097056323521',
];

const VideoPage: SSRFunctionComponent<VideoPageProps, { id: string }> = (props) => {
  const {
    video,
    staticContext,
    onLoadSuccess = () => {},
    onLoadError = () => {},
  } = props;

  const { t, i18n: { language } } = useTranslation(['page', 'common']);
  const history = useHistory();
  const location = useLocation();
  const query = useQuery(true);
  const params: { id?: string } = useParams();
  const { action, autoplay } = query;

  const recommendedItems = useRecommendedItems(video?.catalogueId, language, video?.type === 'OneOff' || video?.type === 'Episode' ? '16:9' : '2:3');

  useEffect(() => {
    if (video) {
      DataLayer.updatePageAttributesForVideo(video);

      if (!video?.expired) {
        onLoadSuccess(video.pageTitle);
      }
    }

    if (video === null || video?.expired) {
      onLoadError('404', 'Page Not Found');
    }

    return (() => {
      DataLayer.resetPageAttribute();
    });
  }, [video, action, autoplay, history, onLoadSuccess, onLoadError]);

  if (video === null || video?.expired) {
    // if static context available, set the status code to 404 for ssr
    if (staticContext) {
      staticContext.statusCode = 404;
    }
    return <NotFoundError/>;
  }

  if (staticContext && video?.isLiveStream) {
    // we want a shorter s-maxage (used by cloudfront cache) for a live stream page
    staticContext.cacheControl = 'public,max-age=60,s-maxage=60';
  }

  if (!video) {
    return null;
  }

  if (action === 'play' || autoplay === '1') {
    const _query = { ...query };

    // remove the autoplay and action query string from the redirect url
    delete _query.autoplay;
    delete _query.action;

    const to = generatePathFromLinkProps({
      name: 'watch',
      params: { id: video.id },
      query: _query,
    });

    return <HttpRedirect to={to}/>;
  }

  const { id } = params;
  const canonicalUrl = generatePathFromLinkProps(video.route);

  // Always redirect to canonical url if the video id matches but the canonical url is not the current url
  if (id === video.id && canonicalUrl !== location.pathname) {
    try {
      const to = generatePathFromLinkProps({
        ...video.route,
        query,
      });
      return <HttpRedirect to={to}/>;
    } catch (e) {
      Logger.error(new Error('unable to redirect to canonical url'), {
        path: location.pathname,
        videoRoute: video.route,
      });
    }
  }

  const extractImages = (_video: OnDemand.Video) => {
    let unfurlingImageUrl: string;
    let backgroundImageUrl: string;
    let backgroundImageId: string;

    if ('ogImageId' in _video) {
      unfurlingImageUrl = getResizedUrl({ imageId: _video.ogImageId }, { width: 640, height: 360 });
    } else {
      unfurlingImageUrl = _video.images.thumbnailLarge;
    }

    if ('odImageId' in _video) {
      backgroundImageId = _video.odImageId;
    } else {
      backgroundImageUrl = _video.images.hd720 || _video.images.thumbnailLarge || _video.images.thumbnail;
    }

    return {
      unfurlingImageUrl,
      backgroundImageId,
      backgroundImageUrl,
    };
  };

  const getPageTitle = (_video: OnDemand.Video) => {
    // we are not escaping the value to avoid double escaping by react component below
    let pageTitle = t('page:detailsPage.pageTitle', {
      pageTitle: _video.pageTitle,
      interpolation: {
        escapeValue: false,
      },
    });

    if (_video.type === 'Episode') {
      // we are not escaping the value to avoid double escaping by react component below
      pageTitle = t('page:detailsPage.pageTitle', {
        pageTitle: _video.episodeData.programName,
        interpolation: {
          escapeValue: false,
        },
      });
      pageTitle += ': ';

      if (_video.entityType === 'NEWS_EPISODE') {
        pageTitle += _video.pageTitle;
      } else {
        pageTitle += t('page:detailsPage.seasonTitle', {
          seasonNumber: _video.episodeData.seasonNumber,
        });
        pageTitle += ' ';
        pageTitle += t('page:detailsPage.episodeTitle', {
          episodeNumber: _video.episodeData.episodeNumber,
        });
      }
    }

    return pageTitle;
  };

  const { unfurlingImageUrl, backgroundImageUrl, backgroundImageId } = extractImages(video);
  const pageTitle = getPageTitle(video);

  // we are not escaping the value to avoid double escaping by react component below
  const pageDescription = t('page:detailsPage.pageDescription', {
    pageTitle: video.pageTitle,
    interpolation: {
      escapeValue: false,
    },
  });

  return (
    <div>
      <Helmet
        titleTemplate="%s | Stream free on SBS On Demand"
      >
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription}/>
        <script type="application/ld+json">
          {JSON.stringify(StructuredData.generateVideoSchema(video, language))}
        </script>
        <link href={generateFullUrlFromLinkProps(video.route, language)} rel="canonical"/>
      </Helmet>
      <AlternateLinks/>
      <UnfurlingMetaTags
        title={pageTitle}
        type={getOgType(video)}
        description={video.metaDescription}
        imageUrl={unfurlingImageUrl}
        url={generateFullUrlFromLinkProps(video.route, language)}
      />
      <DetailsPageLayout backgroundImage={backgroundImageUrl} backgroundImageId={backgroundImageId}>
        <VideoSummary video={video}/>
        <PageRows rows={[
          {
            id: `recommendation-shelf-${video.catalogueId}`,
            type: 'recommendation-shelf',
            catalogueId: video.catalogueId,
            name: 'You May Also Like',
            collection: {
              id: `recommendation-shelf-${video.catalogueId}`,
              title: 'You May Also Like',
              layout: 'shelf',
              displayType: video.type === 'OneOff' || video.type === 'Episode' ? '16:9' : '2:3',
              route: null,
              items: recommendedItems,
            },
          },
        ]}
        />
      </DetailsPageLayout>
    </div>
  );
};

VideoPage.getInitialProps = async ({ match: { params: { id } }, language }) => {
  const video = await getMpxMediaById(id, language);

  if (typeof window === 'undefined') {
    logVideoNotMatching(id, video, { component: 'VideoPage' });
  }
  return { video, rows: [] };
};

export default VideoPage;
