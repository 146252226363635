import { Hidden, IconButton } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MuiCarousel from 'react-material-ui-carousel';

import OnDemand from '../../@types/OnDemand';
import { ReactComponent as CarouselPause } from '../../images/icons/carousel-pause.svg';
import { ReactComponent as CarouselPlay } from '../../images/icons/carousel-play.svg';
import { ReactComponent as NavArrow } from '../../images/icons/nav-arrow.svg';
import blue from '../../styles/colors/blue';
import grey from '../../styles/colors/grey';
import orange from '../../styles/colors/orange';
import TileHero from '../Tiles/TileHero';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      position: 'relative',
      marginBottom: '-17.5vw',
      [theme.breakpoints.down('sm')]: {
        marginBottom: -80,
        '@media only screen and (max-height: 720px)': {
          marginBottom: 'max(-390px, calc(-800px + min(670px, 100vh)))',
        },
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: -60,
      },
      ':focus:not(.focus-visible)': {
        outline: `3px solid ${blue.navy}`,
      },
      'body:not(.using-mouse) & $button:focus': {
        opacity: 1,
      },
      '&:hover $button': {
        '@media (hover: hover)': {
          filter: 'brightness(120%)',
          opacity: 0.7,
        },
        [theme.breakpoints.down('sm')]: {
          '@media (hover: none)': {
            opacity: 0,
          },
        },
      },
      '& $button': {
        '@media (hover: hover)': {
          '&:hover': {
            opacity: '1 !important',
          },
        },
      },
    },
    carousel: {
      height: '56.25vw',
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
      },
    },
    buttonWrapper: {
      height: 'auto',
      '&:hover': {
        '& $button': {
          backgroundColor: grey.white,
        },
      },
    },
    buttonWrapperNext: {
      '& $button': {
        borderRadius: '50% 0 0 50%',
      },
    },
    buttonWrapperPrev: {
      '& $button': {
        borderRadius: '0 50% 50% 0',
      },
    },
    button: {
      zIndex: 99,
      backgroundColor: grey.white,
      color: grey.black,
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.8rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '2rem',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      '@media (hover:none)': {
        // do not display button on non hover device
        display: 'none',
      },
      '&:focus:not(.focus-visible)': {
        outline: `3px solid ${blue.navy}`,
      },
    },
    navIcon: {
      opacity: 0.75,
    },
    nextIcon: {
      transform: 'rotate(90deg) translateY(-5px)',
    },
    prevIcon: {
      transform: 'rotate(-90deg) translateY(-5px)',
    },
    indicators: {
      position: 'absolute',
      bottom: 'calc(21vw - 30px)',
      left: '8%',
      marginLeft: -4,
      textAlign: 'left',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        bottom: 110,
        '@media only screen and (max-height: 720px)': {
          bottom: 'min(380px, calc(815px - min(670px, 100vh)))',
        },
      },
      [theme.breakpoints.down('xs')]: {
        left: 0,
        paddingLeft: 30,
        bottom: 90,
      },
    },
    indicator: {
      padding: '20px 4px',
      opacity: 1,
      borderRadius: 2,
      // MuiCarousel has a default transition of 200ms which is not needed and in combo with the transform fix below
      // would trigger Lighthouse non-composited animation error
      transition: 'none',
      // set translateZ(0) to fix overflow hidden with border radius on safari
      transform: 'translateZ(0)',
      overflow: 'hidden',
      '&:focus': {
        outline: `3px solid ${blue.navy}`,
      },
      '&:hover': {
        background: 'none',
      },
    },
    indicatorActive: {
      '& $indicatorBar, & ~ $indicator $indicatorBar': {
        backgroundColor: alpha(common.white, 0.5),
        '$indicator:hover &': {
          '@media (hover: hover)': {
            backgroundColor: grey.white,
          },
        },
      },
      '.play & $indicatorBarFill': {
        transform: 'translateX(0)',
        transition: 'transform 10s linear',
      },
    },
    indicatorBar: {
      width: 32,
      height: 4,
      borderRadius: 2,
      overflow: 'hidden',
      backgroundColor: orange.darkTangerine,
      '$indicator:hover &': {
        '@media (hover: hover)': {
          backgroundColor: grey.white,
        },
      },
      [theme.breakpoints.down('xs')]: {
        width: 16,
      },
    },
    indicatorBarFill: {
      width: '100%',
      height: '100%',
      backgroundColor: orange.darkTangerine,
      borderRadius: 2,
      transform: 'translateX(-100%) translateZ(0)',
      willChange: 'transform',
    },
    toggleAutoPlay: {
      position: 'absolute',
      right: 60,
      bottom: 'calc(21vw + 28px)',
      opacity: 0,
      willChange: 'opacity',
      transition: 'opacity 200ms',
      background: grey.white,
      padding: 6,
      '$root:hover &': {
        opacity: 0.7,
        '&:hover': {
          opacity: 1,
          background: grey.white,
        },
      },
      '&:focus': {
        opacity: 0.7,
        background: grey.white,
        outline: `3px solid ${blue.navy}`,
        borderRadius: 20,
      },
      [theme.breakpoints.down('sm')]: {
        bottom: 159,
      },
      [theme.breakpoints.down('xs')]: {
        bottom: 77,
      },
    },
  });
});

declare module 'react-material-ui-carousel' {
  interface CarouselProps {
    classes?: {
      root?: string;
      buttonWrapper?: string;
      button?: string; // next or prev button
      next?: string; // next button
      prev?: string; // prev button
      indicators?: string; // indicators container
      indicator?: string; // individual indicator
      active?: string; // active indicator
    };
  }
}

interface CarouselComponentProps {
  items: OnDemand.Hero[];
  rowIndex: number;
}

const HeroCarousel: FunctionComponent<CarouselComponentProps> = (props) => {
  const { items, rowIndex } = props;
  const classes = useStyles({});
  const { t } = useTranslation('common');

  const getShelfLocation = (tileIndex) => {
    return `carousel:hero:${rowIndex + 1}:${tileIndex + 1}`;
  };

  const [autoPlay, setAutoPlay] = useState<boolean>(true);
  const [playClassName, setPlayClassName] = useState<string>('');

  const toggleAutoPlay = useCallback(() => {
    setAutoPlay((_autoPlay) => {
      return !_autoPlay;
    });
  }, [setAutoPlay]);

  // we need to set the class after the render so that the first indicator animation can start
  useEffect(() => {
    const className = autoPlay ? 'play' : '';
    setPlayClassName(className);
  }, [autoPlay]);

  if (items && items.length) {
    return (
      <div className={`${classes.root} ${playClassName}`} data-testid="hero-carousel">
        <MuiCarousel
          classes={{
            root: classes.carousel,
            buttonWrapper: classes.buttonWrapper,
            button: classes.button,
            next: classes.buttonWrapperNext,
            prev: classes.buttonWrapperPrev,
            indicators: classes.indicators,
            indicator: classes.indicator,
            active: classes.indicatorActive,
          }}
          NextIcon={<NavArrow className={clsx(classes.nextIcon, classes.navIcon)}/>}
          PrevIcon={<NavArrow className={clsx(classes.prevIcon, classes.navIcon)}/>}
          IndicatorIcon={(
            <div className={classes.indicatorBar}>
              <div className={classes.indicatorBarFill}/>
            </div>
          )}
          timeout={250}
          interval={10000}
          autoPlay={autoPlay}
          stopAutoPlayOnHover={false}
          fullHeightHover={false}
        >
          {
            items.map((heroItem, index) => {
              return (
                <TileHero
                  key={heroItem.item.id}
                  clickSource={`carousel:hero:${rowIndex + 1}:${index + 1}`}
                  shelfLocation={getShelfLocation(index)}
                  rowIndex={rowIndex}
                  tileIndex={index}
                  item={heroItem.item}
                  title={heroItem.title}
                  imageProps={heroItem.imageProps}
                  tagLine={heroItem.tagLine}
                  body={heroItem.body}
                />
              );
            })
          }
        </MuiCarousel>
        <Hidden smDown>
          <IconButton
            color="primary"
            aria-label={autoPlay ? t('heroCarousel.pauseCarousel') : t('heroCarousel.playCarousel')}
            component="span"
            onClick={toggleAutoPlay}
            className={classes.toggleAutoPlay}
          >
            {
              autoPlay ? <CarouselPause/> : <CarouselPlay/>
            }
          </IconButton>
        </Hidden>
      </div>
    );
  }

  return null;
};

export default HeroCarousel;
