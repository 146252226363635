import axios, { AxiosResponse } from 'axios';

import { components } from '@@types/UserProfile';
import { getSbsLoginInstance } from '@@utils/SbsLoginUtils';
import { NEWSLETTER_SUBSCRIPTIONS_PATH } from '@@utils/constants';
import Logger from '@@utils/logger/Logger';

export const httpClient = axios.create({
  baseURL: process.env.BVAR_USER_PROFILE_API_HOST,
});

httpClient.interceptors.request.use(async (axiosConfig) => {
  const localConfig = { ...axiosConfig };
  const sbsLogin = await getSbsLoginInstance();
  return sbsLogin.getAccessToken()
    .then((accessToken) => {
      localConfig.headers.Authorization = `Bearer ${accessToken}`;
      return localConfig;
    });
}, (error) => {
  return Promise.reject(error);
});

export interface GetSubscriptionsResponse extends AxiosResponse {
  data: Record<string, components['schemas']['Newsletter']>;
}

export function getSubscriptions(): Promise<Record<string, components['schemas']['Newsletter']>> {
  Logger.info('UserProfileApi: fetching newsletter subscriptions');
  return httpClient.get(NEWSLETTER_SUBSCRIPTIONS_PATH)
    .then(({ data }: GetSubscriptionsResponse) => {
      return Object.keys(data)
        .filter((key) => {
          return data[key].subscribed === true;
        })
        .reduce((obj, key) => {
          const reduced = { ...obj };
          reduced[key] = data[key];
          return reduced;
        }, {});
    })
    .catch((e) => {
      Logger.error('Unable to fetch newsletter subscriptions', {
        error: {
          message: e.message,
        },
      });
      throw new Error('Failed fetching newsletter subscriptions');
    });
}

export function updateSubscriptions(payload: components['schemas']['NewsletterUpdates']):Promise<AxiosResponse> {
  Logger.info('UserProfileApi: update newsletter subscriptions', payload);
  return httpClient.patch(NEWSLETTER_SUBSCRIPTIONS_PATH, payload);
}
