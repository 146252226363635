import { useEffect, useState } from 'react';

import { getMoreLikeThis } from '@@src/apis/RecommendationApi';
import { useAppSelector } from '@@src/hooks/store';
import { transformRecommendedItems } from '@@src/transformers/CatalogApiCollectionTransformer';
import { getConfig } from '@@stores/ConfigStore';

const useRecommendedItems = (entityId: string | undefined, language: string, aspectRatio: '16:9' | '2:3') => {
  const [recommendedItems, setRecommendedItems] = useState([]);

  const config = useAppSelector((state) => {
    return getConfig(state);
  });

  useEffect(() => {
    if (!entityId) {
      return;
    }

    const fetchRecommendedItems = async () => {
      try {
        const response = await getMoreLikeThis(entityId, language);

        setRecommendedItems(
          transformRecommendedItems(
            response.recommendedItems,
            aspectRatio,
            response.recommendationID,
            response.recommendationVariant,
          ),
        );
      } catch (_error) {
        // Handle the error (no-op)
      }
    };

    fetchRecommendedItems();
  }, [aspectRatio, entityId, language, config]); // is it necessary to listen for config changes?

  return recommendedItems;
};

export default useRecommendedItems;
