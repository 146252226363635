import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import LazyLoad from 'react-lazyload';

import OnDemand2 from '@@src/@types/OnDemand2';
import ContinueWatchingCarouselShelf from '@@src/components/Shelf/ContinueWatchingCarouselShelf';
import LiveScheduleShelf from '@@src/components/Shelf/LiveScheduleShelf';
import { useAppSelector } from '@@src/hooks/store';
import { OdLinkProps } from '@@src/routes';
import { getIsLoggedIn } from '@@stores/UserStore';
import OnDemand from '@@types/OnDemand';
import Logger from '@@utils/logger/Logger';

import NewsletterPromoBlock from '../Advert/NewsletterPromoBlock';
import PromoBlock from '../Advert/PromoBlock';
import HeroCarousel from '../Carousel/HeroCarousel';
import CarouselShelf from '../Shelf/CarouselShelf';
import CarouselShelfV2 from '../Shelf/CarouselShelfV2';
import GridShelf from '../Shelf/GridShelf';
import GridShelfV2 from '../Shelf/GridShelfV2';
import PlaceholderShelf from '../Shelf/PlaceholderShelf';
import ShortcutShelf, { ShortcutItem } from '../Shelf/ShortcutShelf';

// Old Row Types
interface BaseRow {
  id?: string;
  name: string;
  requireLogin?: boolean;
  /** @deprecated: use displayType instead */
  itemType?: string;
  displayType?: string;
  feedUrl?: string;
  rangeTo?: number;
  route?: OdLinkProps,
  layout?: string;
}

export interface ShortcutRow extends BaseRow {
  type: 'shortcuts';
  items?: ShortcutItem[];
}

export interface HeroRow extends BaseRow {
  type: 'carousel';
  items?: OnDemand.Hero[];
}

export interface ShelfRow extends BaseRow {
  type: 'shelf';
  items?: OnDemand.Tile[];
  totalItems?: number;
}

export type RowV2 = {
  id: string;
  type: 'shelfV2' | 'gridV2' | 'listV2';
  collection: OnDemand2.Collection;
};

export interface ContinueWatchingShelfRow extends Omit<RowV2, 'type'> {
  id: string;
  type: 'continue-watching-shelf';
  collection: OnDemand2.Collection;
  displayType?: string;
  ref?: {
    url: string
  }
}

export interface RecommendationShelfRow extends BaseRow {
  type: 'recommendation-shelf';
  catalogueId: string;
  collection: OnDemand2.Collection;
  totalItems?: number;
}

export interface GridRow extends BaseRow {
  type: 'grid';
  items?: OnDemand.Tile[];
  totalItems?: number;
}

export interface PromoBlockRow extends BaseRow {
  type: 'promo-block';
  promo: {
    name: string;
    images: {
      xs: string;
      sm: string;
      md: string;
    };
    route?: OdLinkProps,
    click?: {
      url: string;
    };
  };
  ad?: {
    pageSlug: string;
    pos: number;
    targeting: Record<string, string>;
  }
}

export interface NewsletterPromoBlockRow extends BaseRow {
  type: 'newsletter-promo-block';
}

export type PageRowType = HeroRow | ShelfRow | RecommendationShelfRow
| GridRow | PromoBlockRow | ShortcutRow | NewsletterPromoBlockRow | RowV2 | ContinueWatchingShelfRow;

export interface PageRowProps {
  row: PageRowType;
  rowIndex: number;
}

const useStyles = makeStyles<Theme>((theme: Theme) => {
  return createStyles({
    row: {
      position: 'relative',
    },
    withVerticalSpacing: {
      marginTop: 40,
      marginBottom: 40,
      [theme.breakpoints.up('md')]: {
        marginTop: 50,
        marginBottom: 50,
      },
      [theme.breakpoints.up('xl')]: {
        marginTop: 40,
        marginBottom: 40,
      },
    },
  });
});

const PageRow: FunctionComponent<PageRowProps> = (props) => {
  const { rowIndex, row } = props;
  const classes = useStyles({});
  const loggedIn = useAppSelector(getIsLoggedIn);

  if (row?.type) {
    let rowContent = null;

    if ('requireLogin' in row && row.requireLogin && !loggedIn) {
      return null;
    }

    switch (row.type) {
      case 'carousel':
        rowContent = <HeroCarousel items={row.items} rowIndex={rowIndex}/>;
        break;

      case 'shortcuts':
        rowContent = <ShortcutShelf items={row.items} rowIndex={rowIndex} title={row.name}/>;
        break;

      case 'shelf':
        if (row.displayType === 'LIVE_SCHEDULE') {
          rowContent = <LiveScheduleShelf row={row} rowIndex={rowIndex}/>;
        } else {
          rowContent = <CarouselShelf row={row} rowIndex={rowIndex}/>;
        }
        break;

      case 'continue-watching-shelf':
        rowContent = (
          <ContinueWatchingCarouselShelf row={row} rowIndex={rowIndex}/>
        );
        break;

      case 'shelfV2':
        rowContent = (
          <LazyLoad
            offset={100}
            unmountIfInvisible
            placeholder={<PlaceholderShelf collectionTitle={row.collection.title} collectionDisplayType={row.collection.displayType}/>}
            // if the row items is to be loaded asynchonously, then only lazyload it once
            // or else it will keep disappearing/reappearing instead of showing the placeholder
            once={'route' in row.collection}
          >
            <CarouselShelfV2 collection={row.collection} rowIndex={rowIndex}/>
          </LazyLoad>
        );
        break;

      case 'recommendation-shelf':
        rowContent = <CarouselShelfV2 collection={row.collection} rowIndex={rowIndex}/>;
        break;

      case 'promo-block':
        rowContent = (
          <div>
            <PromoBlock
              row={row}
              rowIndex={rowIndex}
            />
          </div>
        );
        break;

      case 'newsletter-promo-block':
        rowContent = <NewsletterPromoBlock/>;
        break;

      case 'grid':
        rowContent = (
          <GridShelf row={row} rowIndex={rowIndex}/>
        );
        break;

      case 'gridV2':
        rowContent = <GridShelfV2 collection={row.collection}/>;
        break;

      default:
        Logger.warn('Row type not supported', { row });
        break;
    }

    if (rowContent) {
      if (row.type === 'carousel' || row.type === 'shortcuts') {
        return <div className={classes.row}>{rowContent}</div>;
      }
      return <div className={clsx(classes.row, classes.withVerticalSpacing)}>{rowContent}</div>;
    }
  }

  return null;
};

export default PageRow;
